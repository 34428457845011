import React, {useState} from 'react';
import * as Ant from 'antd';
import SpecImageEditor from '../../Components/SpecImageEditor';
import Tabs from '../../Widgets/Tabs';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';

export default function EditorBlock(props) {
  const {Input, Collapse, Checkbox} = Ant;
  const {Panel} = Ant.Collapse;
  const {record, setRecord, designSpec, setDesignSpec} = props;
  const [chosenTab, setChosenTab] = useState('1');

  // 新增規格
  const addEditorOption = () => {
    const newEditorOptions = designSpec.editor_options || [];
    newEditorOptions.push({
      editor_id: `新增規格${newEditorOptions.length + 1}`,
      mask_image: null,
      die_cut_image: null,
      preview_images: [],
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 編輯規格識別碼
  const editEditorId = (idx, value) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions[idx].editor_id = value;
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 刪除規格
  const deleteEditorOption = (editor_id) => {
    const newEditorOptions = designSpec.editor_options.filter(
      (option) => option.editor_id !== editor_id,
    );
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 新增 editor option 內對應 editor_id 的 type 圖片
  const addEditorOptionImage = ({editorId, type, value}) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions.forEach((option) => {
      if (option.editor_id === editorId) {
        if (type === 'preview_images') {
          option[type].push(value);
        } else {
          option[type] = value;
        }
      }
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 刪除 editor option 內對應 editor_id 的 type 圖片
  const deleteEditorOptionImage = ({editorId, type, index}) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions.forEach((option) => {
      if (option.editor_id === editorId) {
        if (type === 'preview_images') {
          option[type].splice(index, 1);
        } else {
          option[type] = null;
        }
      }
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  const editedItems = [
    {
      key: '1',
      label: '商品編輯器',
      children: (
        <div className="content">
          <div
            className="row"
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}>
            <Ant.Radio.Group
              value={designSpec.die_cut_type}
              // 目前只有一個選項
              // onChange={(e) => {
              //   setDesignSpec({
              //     ...designSpec,
              //     die_cut_type: e.target.value,
              //   });
              // }}
            >
              <Ant.Radio value="default">預設刀模</Ant.Radio>
              <Ant.Radio value="custom" disabled>
                客製化刀模
              </Ant.Radio>
            </Ant.Radio.Group>
            <p style={{color: '#9B9B9B'}}>
              後台人員定義刀模，關閉前台編輯刀模功能
            </p>
            <Button onClick={addEditorOption}>新增規格</Button>
          </div>
          <Collapse defaultActiveKey={['0']} ghost>
            {designSpec &&
              designSpec.editor_options &&
              designSpec.editor_options.map((option, index) => (
                <Panel header={option.editor_id} key={index}>
                  <Row
                    style={{
                      marginBottom: '15px',
                      alignItems: 'flex-start',
                      gap: '5px',
                    }}>
                    <h4 style={{width: 'fit-content', alignSelf: 'flex-start'}}>
                      *刀模圖片識別碼
                    </h4>
                    <Input
                      placeholder={'請輸入刀模圖片識別碼 ' + (index + 1)}
                      value={option.editor_id}
                      onChange={(e) => editEditorId(index, e.target.value)}
                    />
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        if (window.confirm('確定要刪除？')) {
                          deleteEditorOption(option.editor_id);
                        }
                      }}>
                      刪除
                    </Button>
                  </Row>
                  <SpecImageEditor
                    item={option.mask_image}
                    name="遮罩圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'mask_image',
                        value,
                      })
                    }
                    onDelete={() =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'mask_image',
                      })
                    }
                    needed
                  />
                  <SpecImageEditor
                    item={option.die_cut_image}
                    name="刀模圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'die_cut_image',
                        value,
                      })
                    }
                    onDelete={() =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'die_cut_image',
                      })
                    }
                    needed
                  />
                  <SpecImageEditor
                    item={option.preview_images}
                    name="預覽圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'preview_images',
                        value,
                      })
                    }
                    onDelete={(index) =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'preview_images',
                        index,
                      })
                    }
                    imageNumLimit={3}
                    needed
                  />
                </Panel>
              ))}
          </Collapse>
        </div>
      ),
    },
    {
      key: '2',
      label: '其他設定',
      children: (
        <div className="content">
          <Row style={{gap: '8px'}}>
            白墨編輯
            <Checkbox
              checked={designSpec.is_white_ink}
              onChange={(e) =>
                setDesignSpec({
                  ...designSpec,
                  is_white_ink: e.target.checked,
                })
              }
            />
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <h2>稿件線上編輯</h2>
      <div className="content">
        <div className="row">
          <label>
            <Ant.Checkbox
              checked={record.is_editable}
              onChange={(e) => setRecord({is_editable: e.target.checked})}
              style={{
                marginRight: '8px',
              }}
            />{' '}
            線上編輯
          </label>{' '}
        </div>
        {record.is_editable && (
          <>
            <Tabs
              value={chosenTab}
              onChange={(value) => {
                setChosenTab(value);
              }}
              options={editedItems.map((item) => ({
                label: item.label,
                value: item.key,
              }))}
            />
            <div style={{marginTop: '20px'}}>
              {editedItems.map((item) => {
                return (
                  <div
                    key={item.key}
                    style={{
                      display: chosenTab === item.key ? 'block' : 'none',
                    }}>
                    {item.children}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className="divider" />
    </>
  );
}
